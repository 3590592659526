//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Home",
  data() {
    return {
      productCode: "",
      scanCode: "",
      storeOption: [],
      currentStore: "",
      nowData: [],
      myhrid: "",
    };
  },
  created() {
    window.document.title = "OAdemo";
    this.myhrid = this.$memberInfo.hhrEmpid;
  },
  mounted() {
    window.document.title = "OA流程";
    //this.wxConfig();
  },

  activated() {
    console.log("activated", "HR流程中心");
    this.$dd.ready(() => {
      this.$dd.biz.navigation.setTitle({
        title: "HR流程中心",
      });
    });
  },
};
